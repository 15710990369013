package redux

import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.other.pct

/**
 * The order date
 */
@Deprecated("replaced with ValidatedDiscountCode")
data class AppliedDiscount(
  val code: String,
  val discount: @pct Double,
) {
  companion object {
    val invalidCode: AppliedDiscount = AppliedDiscount(
      "43da09cb-75a8-449d-a49b-8e29d7157b11",
      0.0.percent,
    )
  }
}
