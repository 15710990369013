package it.neckar.lizergy.model.serializers

import de.wunschsolar.model.AlreadyValidatedDiscountCode
import de.wunschsolar.model.SuccessfullyValidatedDiscountCode
import de.wunschsolar.model.UnsuccessfullyValidatedDiscountCode
import de.wunschsolar.model.ValidatedDiscountCode
import it.neckar.open.kotlin.serializers.defaultJsonConfiguration
import kotlinx.serialization.json.JsonBuilder
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.SerializersModuleBuilder
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.serializer

/**
 * Contains the kotlinx serialization configuration for the planner
 */
fun SerializersModuleBuilder.wunschsolarModel() {
  polymorphic(ValidatedDiscountCode::class) {
    subclass(SuccessfullyValidatedDiscountCode::class, serializer())
  }

  polymorphic(ValidatedDiscountCode::class) {
    subclass(UnsuccessfullyValidatedDiscountCode::class, serializer())
  }

  polymorphic(ValidatedDiscountCode::class) {
    subclass(AlreadyValidatedDiscountCode::class, serializer())
  }
}

fun JsonBuilder.wunschsolarJsonConfiguration(prettyPrintEnabled: Boolean = true) {
  defaultJsonConfiguration(prettyPrintEnabled)
  serializersModule = SerializersModule {
    wunschsolarModel()
  }
}
